import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Action from '../components/Resource/Action';
import Button from '../components/Button';
import Video from '../components/Video';
import Image from '../components/Image';
import Icon from '../components/Icon';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);
  const { t } = useTranslation(['career-profiles', 'common']);

  return (
    <Layout title={`${t(profile.title)} | Career Profiles`}>
      <Section>
        <Container>
          <Row>
            <Column>
              <p>
                <strong>
                  <a href="/career-profiles">
                    &lsaquo; View all career profiles
                  </a>
                </strong>
              </p>
              <h1>{t(profile.title)}</h1>
              <div className="career-profile__video-wrapper">
                {sortIndex > 1 && (
                  <Link
                    className="career-profile__switch-button career-profile__prev-button"
                    to={`/career-profiles/${orderLookup[sortIndex - 1]}`}
                  >
                    <Icon name="arrowleft" className="callout__icon" />
                  </Link>
                )}
                <Video
                  guidSrc={t(profile.videoSrc)}
                  poster={profile.videoPoster}
                  title={t(profile.title)}
                />
                {sortIndex < maxCareerIndex && (
                  <Link
                    className="career-profile__switch-button career-profile__next-button"
                    to={`/career-profiles/${orderLookup[sortIndex + 1]}`}
                  >
                    <Icon name="arrowright" className="callout__icon" />
                  </Link>
                )}
              </div>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <h2>{t(profile.introHeader)}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${t(profile.introParagraph)}`,
                }}
              />
              <ul className="career-profile__quicklinks">
                <li>
                  <a href="#about" className="text-bold">
                    {t('common:about')}
                  </a>
                </li>
                <li>
                  <a href="#evaluate-interest" className="text-bold">
                    {t('evaluateHeader')}
                  </a>
                </li>
                <li>
                  <a href="#activate-interest" className="text-bold">
                    {t('activateHeader')}
                  </a>
                </li>
              </ul>
              <h3 id="#about">{t('common:about')}</h3>
              <p>{t(profile.about)}</p>
              <Action
                download={t(profile.aboutPdf)}
                label={t(profile.title)}
                metadata={`PDF, ${t(profile.aboutPdfSize)}`}
              />
              <h3 id="evaluate-interest">{t('evaluateHeader')}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: t(profile.evaluateIntro) }}
              />
              <ul className="bullets">
                {profile.evaluateChecklist.map((item) => {
                  return <li key={item}>{t(item)}</li>;
                })}
              </ul>
              <h3 id="activate-interest">{t('activateHeader')}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: t(profile.activateIntro) }}
              />
              <ul className="bullets">
                {profile.activateChecklist.map((item) => {
                  return <li key={item}>{t(item)}</li>;
                })}
              </ul>
              <Action
                download={t(profile.studentActivationPdf)}
                label={t('buttonLabelStudentActivation')}
                metadata={`PDF, ${profile.studentActivationPdfSize}`}
              />
            </Column>
            <Column size={3} offset={1}>
              <Image
                filename={t(profile.quoteImage)}
                className="quote__headshot"
              />
              <div className="quote__text-wrapper">
                <p
                  className="quote__text"
                  dangerouslySetInnerHTML={{ __html: t(profile.quote) }}
                />
                <p
                  className="quote__attribution"
                  dangerouslySetInnerHTML={{
                    __html: t(profile.quoteAttribution),
                  }}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column>
              <h2>{t('additionalCareersToExploreHeading')}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(profile.additionalCareersParagraph),
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={t(relatedMap[profile.related[0]].relatedImage)}
                  />
                  <h4>{t(relatedMap[profile.related[0]].title)}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        relatedMap[profile.related[0]].shortDescription
                      ),
                    }}
                  />
                  <Button to={t(`/career-profiles/${profile.related[0]}`)}>
                    {t('common:footerPartnerButtonText')}
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={t(relatedMap[profile.related[1]].relatedImage)}
                  />
                  <h4>{t(relatedMap[profile.related[1]].title)}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        t(relatedMap[profile.related[1]].shortDescription)
                      ),
                    }}
                  />
                  <Button to={t(`/career-profiles/${profile.related[1]}`)}>
                    {t('common:footerPartnerButtonText')}
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          about
          additionalCareersParagraph
          activateIntro
          activateChecklist
          aboutPdf
          aboutPdfSize
          evaluateIntro
          evaluateChecklist
          introHeader
          introParagraph
          related
          studentActivationPdf
          studentActivationPdfSize
          videoPoster
          videoSrc
          quoteImage
          quote
          quoteAttribution
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
